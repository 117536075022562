<template>
  <div class="dataViewer">
    <iframe class="iframe_div" :src="url" v-if="url"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      addUrl: "",
      showCreate: false,
    };
  },
  methods: {
    init() {
      this.url = IOT_CONFIG.paths["dataViewer"];
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang='less'>
.dataViewer {
  position: relative;
  .create_btn {
    position: absolute;
    top: 116px;
    right: 60px;
  }
}
</style>